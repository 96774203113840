export const emptyMessage = {
  messageId: "",
  message: "",
  sendTime: "",
  replyMessageId: "",
  senderName: "",
  senderId: "",
  senderAvatar: "",
  messageType: "",
}
